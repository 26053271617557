import { memo, useEffect, useState } from 'react';
import _ from 'lodash';
import baseTheme from 'themes/baseTheme';
import theme from 'themes/baseTheme';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { ExpandMore, RefreshRounded, Visibility, VisibilityOff } from '@mui/icons-material';

import { AutoCompleteInput } from 'components/Common/AutoCompleteOption';

import { AWSResource, OutputType, Slate, SourceType } from 'types';
import { bitRateOptions } from 'utils/eventHelpers';

import OutputTypeBySocial from './OutputTypeBySocial';

const cidrRegex = /^(?:\d{1,3}\.){3}\d{1,3}\/(?:[0-9]|[1-2][0-9]|3[0-2])$/;

export const EventInput = memo(function SourceInput<T extends SourceType | OutputType>({
  spec,
  type,
  inputType,
  details,
  onChange,
  options,
  selectedSources,
  onClickRefreshSlates,
  isFetchingSlates,
  group,
}: {
  spec: any;
  type: T;
  inputType: string;
  details: Record<string, string | number>;
  onChange: (
    type: SourceType | OutputType,
    updatedSourceDetailsKey: string,
    updatedSourceDetailsValue: string | number,
    spec?: any
  ) => void;
  options?: {
    awsResources: AWSResource<any>[];
    slates: Slate[];
  };
  selectedSources?: any;
  onClickRefreshSlates?: () => void;
  isFetchingSlates?: boolean;
  group?: string;
}): JSX.Element {
  const removeSelectedOption = (sourceType, resources) => {
    const filteredResources = resources.filter((resource) => {
      const selectedSourceKey = sourceType === SourceType.ELEMENTAL_LINK ? 'deviceId' : 'slateId';
      return !selectedSources[sourceType].some(
        (selectedSource) => selectedSource[selectedSourceKey] === resource.id
      );
    });

    return _.sortBy(filteredResources, [
      (o) => (o.group === group ? 0 : 1),
      'group',
      (o) => (sourceType === SourceType.ELEMENTAL_LINK ? o.details?.Name : o?.name),
    ]);
  };

  const RenderToggle = () => {
    return (
      <Box sx={{ mb: 1.5 }} textAlign="left">
        <Typography variant="h6" sx={{ color: 'grey.600', fontSize: 15 }}>
          <b>Mode</b>
        </Typography>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <ToggleButtonGroup
            sx={{
              height: '35px',
              '& .MuiToggleButton-root.Mui-selected, & .MuiToggleButton-root.Mui-selected:hover': {
                backgroundColor: baseTheme.palette.primary.main,
                color: 'white',
              },
            }}
            value={details?.['mode']}
            exclusive
            onChange={(handleChange, value) => {
              if (value) onChange(type, 'mode', value, spec);
            }}
          >
            <ToggleButton
              value="Listener"
              sx={{
                padding: '5px 10px',
                letterSpacing: '0.5px',
              }}
            >
              {inputType === 'SOURCE' ? 'Push ' : 'Pull '}
              (Listener)
            </ToggleButton>
            <ToggleButton
              value="Caller"
              sx={{
                padding: '5px 10px',
                letterSpacing: '0.5px',
              }}
            >
              {inputType === 'SOURCE' ? 'Pull ' : 'Push '}
              (Caller)
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>
    );
  };

  switch (type) {
    case SourceType.ELEMENTAL_LINK:
      const currDevice = options.awsResources.find((resource) => resource.id === spec.deviceId);
      return (
        <>
          <Autocomplete
            value={currDevice}
            size="small"
            groupBy={(o) => o.group}
            options={removeSelectedOption(type, options.awsResources)}
            fullWidth
            sx={{
              '& .MuiInputBase-root': {
                width: '100%',
              },
              '& .MuiAutocomplete-endAdornment': {
                'button:first-child': {
                  display: 'none',
                },
              },
            }}
            onChange={(_, value: AWSResource<any>) => {
              onChange(type, 'deviceId', value.id, spec);
            }}
            getOptionLabel={(value: AWSResource<any>) => value?.details?.Name || value.id || ''}
            renderInput={(params) => <TextField {...params} label="Select Device" />}
          />
        </>
      );
    case SourceType.SLATE_INPUT:
      const currSlate = options.slates.find((resource) => resource.id === spec.slateId);

      return (
        <>
          <Stack direction="row" width="100%" spacing={1}>
            <Autocomplete
              value={currSlate}
              size="small"
              groupBy={(o) => o.group}
              options={removeSelectedOption(type, options.slates)}
              fullWidth
              sx={{
                '& .MuiInputBase-root': {
                  width: '100%',
                },
                '& .MuiAutocomplete-endAdornment': {
                  'button:first-child': {
                    display: 'none',
                  },
                },
              }}
              onChange={(_, value: Slate) => onChange(type, 'slateId', value.id, spec)}
              getOptionLabel={(value: Slate) => value.name || ''}
              renderInput={(params) => <TextField {...params} label="Select Slate" />}
            />
            <Tooltip title="refresh">
              <IconButton size="small" onClick={onClickRefreshSlates} disabled={isFetchingSlates}>
                <RefreshRounded />
              </IconButton>
            </Tooltip>
          </Stack>

          <Typography variant="subtitle2" sx={{ color: 'grey.600', my: 1 }}>
            or
          </Typography>
          <Link href="/slates" target="_blank" sx={{ fontSize: 'medium' }}>
            Create New Slate
          </Link>
        </>
      );
    case SourceType.ZIXI:
      return (
        <Typography variant="h6" sx={{ color: 'grey.600' }}>
          <b>{SourceType.ZIXI}</b>
        </Typography>
      );
    case SourceType.SRT:
      if (inputType === 'SOURCE') {
        return (
          <>
            <RenderToggle />
            <Stack direction={'row'} spacing={1}>
              <TextField
                label="Port"
                required
                fullWidth
                size="small"
                type="number"
                value={details?.['port'] || ''}
                onChange={(e) => onChange(type, 'port', e.target.value, spec)}
              />
              {details?.['mode'] === 'Caller' && (
                <TextField
                  required
                  label="Host"
                  fullWidth
                  size="small"
                  value={details?.['host'] || ''}
                  onChange={(e) => onChange(type, 'host', e.target.value, spec)}
                />
              )}
            </Stack>

            <Accordion
              sx={{
                '&.MuiAccordion-root': {
                  padding: 0,
                  fontSize: 13,
                },
                mt: 1,
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  '&.MuiAccordionSummary-root.Mui-expanded': {
                    margin: 0,
                    minHeight: 0,
                  },
                  fontWeight: 500,
                }}
              >
                Additional Configuration
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 1.5 }}>
                <Stack direction="row" spacing={1} flexGrow={1}>
                  <Box sx={{ width: '50%' }}>
                    <AutoCompleteInput
                      label={'Max Bitrate (Mbps)'}
                      freeSolo={false}
                      required
                      options={bitRateOptions}
                      value={
                        details?.['maxBitrate']
                          ? {
                              label: String(details?.['maxBitrate'] + ' Mbps'),
                              value: String(details?.['maxBitrate']),
                            }
                          : null
                      }
                      onChange={(value) => onChange(type, 'maxBitrate', value.value, spec)}
                      customStyle
                    />
                  </Box>
                  <TextField
                    label="Stream Id"
                    size="small"
                    sx={{ mt: 1.5 }}
                    value={details?.['streamId'] || ''}
                    onChange={(e) => onChange(type, 'streamId', e.target.value, spec)}
                  />
                </Stack>
                <PasswordField
                  onChangeHandler={(e) => onChange(type, 'password', e.target.value, spec)}
                  value={details?.['password']}
                />
              </AccordionDetails>
            </Accordion>
          </>
        );
      } else {
        const cidr = details?.['cidr'] as string;
        const isCidrInvalid = cidr && !cidrRegex.test(cidr);
        return (
          <>
            <RenderToggle />

            <Stack direction={'row'} spacing={1} sx={{ my: 1.5 }}>
              <TextField
                label="Inbound port"
                required
                size="small"
                type="number"
                sx={{ width: '50%' }}
                value={details?.['port'] || ''}
                onChange={(e) => onChange(type, 'port', e.target.value, spec)}
              />
              <Box sx={{ width: '50%' }}>
                <OutputTypeBySocial type={type} onChange={onChange} details={details} spec={spec} />
              </Box>
            </Stack>

            {details?.['mode'] == 'Caller' && (
              <TextField
                label="Host"
                required
                size="small"
                fullWidth
                value={details?.['host'] || ''}
                onChange={(e) => onChange(type, 'host', e.target.value, spec)}
              />
            )}

            <Accordion
              sx={{
                mt: 1,
                '&.MuiAccordion-root': {
                  padding: 0,
                  fontSize: 13,
                },
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{
                  backgroundColor: theme.palette.grey[50],
                  '&.MuiAccordionSummary-root.Mui-expanded': {
                    margin: 0,
                    minHeight: 0,
                  },
                  fontWeight: 500,
                }}
              >
                Additional Configuration
              </AccordionSummary>
              <AccordionDetails sx={{ mt: 1.5 }}>
                <Box>
                  {details?.['mode'] === 'Caller' ? (
                    <TextField
                      label="Stream Id"
                      fullWidth
                      size="small"
                      value={details?.['streamId'] || ''}
                      onChange={(e) => onChange(type, 'streamId', e.target.value, spec)}
                    />
                  ) : (
                    <TextField
                      label={isCidrInvalid ? 'Invalid CIDR' : 'Allow CIDR'}
                      size="small"
                      error={isCidrInvalid}
                      fullWidth
                      value={details?.['cidr'] || ''}
                      onChange={(e) => onChange(type, 'cidr', e.target.value, spec)}
                      placeholder="0.0.0.0/0"
                    />
                  )}
                </Box>
                <PasswordField
                  onChangeHandler={(e) => onChange(type, 'password', e.target.value, spec)}
                  value={details?.['password']}
                />
              </AccordionDetails>
            </Accordion>
          </>
        );
      }
    case SourceType.RTMP || OutputType.RTMP:
      if (inputType === 'SOURCE') {
        return (
          <Typography variant="h6" sx={{ color: 'grey.600' }}>
            <b>{SourceType.RTMP}</b>
          </Typography>
        );
      } else {
        const url = details?.['url'] as string;
        return (
          <Box>
            <TextField
              label={
                url !== '' && !url?.startsWith('rtmp://') && !url?.startsWith('rtmps://')
                  ? 'starts with ( rtmp:// or rtmps:// )'
                  : !url?.split('://')[1] && url !== ''
                  ? 'Invalid URL'
                  : 'RTMP Source URL'
              }
              fullWidth
              size="small"
              value={url}
              error={
                url !== '' && !url?.startsWith('rtmp://') && !url?.startsWith('rtmps://')
                  ? true
                  : !url?.split('://')[1] && url !== ''
                  ? true
                  : false
              }
              onChange={(e) => onChange(type, 'url', e.target.value, spec)}
            />
            <Stack direction={'row'} sx={{ mt: 1.5 }} spacing={1} alignItems="center">
              <TextField
                label="Stream ID"
                size="small"
                sx={{ width: '50%' }}
                value={details?.['streamId'] || ''}
                onChange={(e) => onChange(type, 'streamId', e.target.value, spec)}
              />
              <Box sx={{ width: '50%' }}>
                <OutputTypeBySocial type={type} onChange={onChange} details={details} spec={spec} />
              </Box>
            </Stack>
          </Box>
        );
      }
    case OutputType.HLS:
      return (
        <Box sx={{ position: 'relative', height: '80px' }}>
          <Typography variant="h6" sx={{ color: 'grey.600' }}>
            <b>{OutputType.HLS}</b>
          </Typography>
          {inputType === 'OUTPUT' && (
            <Box
              sx={{
                position: 'absolute',
                bottom: '-15px',
                right: '0px',
                width: '50%',
              }}
            >
              <OutputTypeBySocial type={type} onChange={onChange} details={details} />
            </Box>
          )}
        </Box>
      );
    default:
      return <></>;
  }
});

const PasswordField = ({
  value,
  onChangeHandler,
}: {
  value: any;
  onChangeHandler: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [error, setError] = useState(false);

  useEffect(() => {
    if (error && value.length >= 10) {
      setError(false);
    }
  }, [value]);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      size="small"
      value={value || ''}
      onChange={(e) => onChangeHandler(e)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              onMouseUp={handleMouseUpPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={error}
      onBlur={() => {
        value && value.length < 10 ? setError(true) : setError(false);
      }}
      fullWidth
      label={error ? 'Password (min length 10)' : 'Password'}
      aria-label="Password"
      sx={{ mt: 1.5 }}
    />
  );
};
