import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addHours, isAfter } from 'date-fns';
import generateUniqueId from 'generate-unique-id';
import _ from 'lodash';
import { calenderTheme } from 'themes/calenderTheme';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Menu,
  MenuItem,
  TextField,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import { DesktopDatePicker, DesktopTimePicker, MobileDatePicker } from '@mui/x-date-pickers';
import { AddRounded } from '@mui/icons-material';

import { AutoCompleteInput } from 'components/Common/AutoCompleteOption';
import { Center } from 'components/Common/Centered';
import { EventElementInputBox } from 'components/Event/EventElementInputBox';
import { EventInput } from 'components/Event/EventInput';

import * as EventService from 'services/eventService';
import * as SourceService from 'services/sourceService';

import { useToaster } from 'contexts/ToasterContext';

import {
  DATE_FORMAT,
  OutputTypeDisplayName,
  SourceTypeDisplayName,
} from 'constants/CommonConstants';
import { OutputConfigDetail, OutputsCount } from 'constants/EventConstants/OutputConstants';
import { SourceConfigDetail, SourceCount } from 'constants/EventConstants/SourceConstants';

import {
  AWSResourceType,
  EventType,
  GroupInfo,
  OutputType,
  PopUpButtonProps,
  SourceType,
  UpdateEventPayload,
} from 'types';
import {
  areAllObjectValuesValid,
  checkObjectNotEmpty,
  eventsTypeOptions,
} from 'utils/eventHelpers';
import { timeZoneAbbreviated, uuid } from 'utils/helpers';

import { PopUp } from '.';
import { AdminGroupNames } from '../../constants/TeamConstants';
import { GroupInfoContext } from '../../contexts/GroupInfoContext';

interface EventPopUpProps {
  isEditMode?: boolean;
  isPopUpOpen: boolean;
  eventToEdit?: string;
  close: () => void | Promise<void>;
}

const EventSteps = [
  { name: 'Event Details' },
  { name: 'Configure Sources' },
  { name: 'Add Outputs' },
];

export function EventPopUp({
  isPopUpOpen,
  close,
  isEditMode,
  eventToEdit,
}: EventPopUpProps): JSX.Element {
  const queryClient = useQueryClient();
  const { triggerToast } = useToaster();
  const { groups, isUserAdminGroup, currentUserGroup } = useContext(GroupInfoContext);
  const isUserAdmin = isUserAdminGroup();
  const [selectedGroup, setselectedGroup] = useState<GroupInfo>(null);
  const [selectedEventType, setSelectedEventType] = useState<EventType>(null);
  const [selectedSourceArray, setSelectedSourceArray] = useState([]);
  const [selectedOutputArray, setSelectedOutputArray] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [addSourcesMenuAnchorEl, setAddSourcesMenuAnchorEl] = useState(null);
  const isAddSourcesMenuOpen = Boolean(addSourcesMenuAnchorEl);
  const [addOutputsMenuAnchorEl, setAddOutputsMenuAnchorEl] = useState(null);
  const isAddOutputsMenuOpen = Boolean(addOutputsMenuAnchorEl);
  const [inputEventName, setInputEventName] = useState('');
  const [inputEventDate, setInputEventDate] = useState(addHours(new Date(), 1));
  const [selectedSources, setSelectedSources] = useState<{ [K in string]: any }>({});
  const [selectedOutputs, setSelectedOutputs] = useState<{ [K in string]: any }>({});
  const nonAdminGroups = groups.filter(
    (group) => !Object.keys(AdminGroupNames).includes(group.name)
  );
  const timeZone = timeZoneAbbreviated();
  const isMounted = useRef(false);

  const getUnSelectedSources = () => {
    const currSelectedSources = Object.keys(selectedSources);
    const multipleSources = _.compact(
      Object.keys(SourceConfigDetail).map((source) => {
        const value = SourceConfigDetail[source as SourceType];
        return value.isMultiple ? source : undefined;
      })
    ) as SourceType[];

    const unselectedSourcesFinal: SourceType[] = [];
    Object.keys(SourceType).forEach((source) => {
      if (!currSelectedSources.includes(source) && source !== SourceType.ZIXI_MULTI) {
        unselectedSourcesFinal.push(source as SourceType);
      }
    });

    multipleSources.forEach((sourceType) => {
      if (!unselectedSourcesFinal.includes(sourceType)) {
        unselectedSourcesFinal.push(sourceType);
      }
    });
    return unselectedSourcesFinal;
  };

  const getUnSelectedOutputs = () => {
    const currSelectedOutputs = Object.keys(selectedOutputs);
    const multipleOutputs = _.compact(
      Object.keys(OutputConfigDetail).map((output) => {
        const value = OutputConfigDetail[output as OutputType];
        return value.isMultiple ? output : undefined;
      })
    ) as OutputType[];

    const unselectedOutputsFinal: OutputType[] = [];
    Object.keys(OutputType).forEach((output) => {
      if (!currSelectedOutputs.includes(output) && output !== OutputType.COMMONCHANNEL) {
        unselectedOutputsFinal.push(output as OutputType);
      }
    });

    multipleOutputs.forEach((outputType) => {
      if (!unselectedOutputsFinal.includes(outputType)) {
        unselectedOutputsFinal.push(outputType);
      }
    });

    return unselectedOutputsFinal;
  };

  const handleOnUnSelectSource = (sourceType: SourceType, spec: any) => {
    setSelectedSourceArray(
      selectedSourceArray.filter((source) => {
        if (source.type === sourceType && _.isEqual(source.spec, spec)) {
          return false;
        } else {
          return true;
        }
      })
    );

    const sources = { ...selectedSources };
    const filteredSources = selectedSources[sourceType].filter((sourceSpecification) => {
      if (_.isEqual(sourceSpecification, spec)) {
        return false;
      } else {
        return true;
      }
    });
    if (filteredSources.length === 0) {
      delete sources[sourceType];
    } else {
      sources[sourceType] = filteredSources;
    }

    setSelectedSources(sources);
  };

  const handleOnUnSelectOutput = (outputType: OutputType, spec: any) => {
    setSelectedOutputArray(
      selectedOutputArray.filter((output) => {
        if (output.type === outputType && _.isEqual(output.spec, spec)) {
          return false;
        } else {
          return true;
        }
      })
    );
    const outputs = { ...selectedOutputs };
    const filteredOutputs = selectedOutputs[outputType].filter((outputSpecification) => {
      if (_.isEqual(outputSpecification, spec)) {
        return false;
      } else {
        return true;
      }
    });

    if (filteredOutputs.length === 0) {
      delete outputs[outputType];
    } else {
      outputs[outputType] = filteredOutputs;
    }

    setSelectedOutputs(outputs);
  };

  /*
  ...............................................
  ...............................................
  Note: For both source and output, 'spec' represents the index of the source/output to maintain their order.
  It's an 8-digit random string provided by the uuid package.
  In the 'editEvent' mode, 'spec' serves as the id from the API for the source/output, acting as their index.
  ...............................................
  ...............................................
*/

  const handleSelectSource = (sourceType: SourceType) => {
    const addSourceItem = (type: string, item?: any) => {
      const array = selectedSources[type] || [];
      array.push(item);
      setSelectedSourceArray([
        ...selectedSourceArray,
        {
          type,
          spec: item,
        },
      ]);
      setSelectedSources({
        ...selectedSources,
        [type]: array,
      });
    };
    switch (sourceType) {
      case SourceType.ELEMENTAL_LINK:
        addSourceItem(sourceType, { deviceId: '', index: `${uuid(8)}_dummy` });
        break;

      case SourceType.SLATE_INPUT:
        addSourceItem(sourceType, { slateId: '', index: `${uuid(8)}_dummy` });
        break;

      case SourceType.SRT:
        addSourceItem(sourceType, {
          port: _.random(50000, 51000).toString(),
          streamId: '',
          password: '',
          maxBitrate: '150',
          host: '',
          mode: 'Listener',
          index: `${uuid(8)}_dummy`,
        });
        break;
      case SourceType.RTMP:
      case SourceType.ZIXI: {
        addSourceItem(sourceType, { index: `${uuid(8)}_dummy` });
        break;
      }
    }
  };

  const handleSelectOutput = (outputType: OutputType) => {
    const addOutputItem = (type: string, item: any) => {
      const array = selectedOutputs[type] || [];
      array.push(item);
      setSelectedOutputArray([
        ...selectedOutputArray,
        {
          type,
          spec: item,
        },
      ]);

      setSelectedOutputs({
        ...selectedOutputs,
        [type]: array,
      });
    };

    switch (outputType) {
      case OutputType.HLS:
        addOutputItem(outputType, { tag: 'NHL.com', index: `${uuid(8)}_dummy` });
        break;

      case OutputType.SRT:
        addOutputItem(outputType, {
          host: '',
          port: _.random(50000, 51000).toString(),
          password: '',
          tag: '',
          cidr: '0.0.0.0/0',
          streamId: '',
          mode: 'Listener',
          index: `${uuid(8)}_dummy`,
        });
        break;
      case OutputType.RTMP:
        addOutputItem(outputType, { url: '', streamId: '', tag: '', index: `${uuid(8)}_dummy` });
        break;
    }
  };

  const handleSourceSpecificationsChange = <T extends SourceType>(
    type: T,
    key: string,
    value: any,
    spec: any
  ) => {
    setSelectedSources((sources) => {
      const index = _.findIndex(sources[type], ['index', spec.index]);
      if (index === -1) {
        return sources;
      }
      const updatedSource = { ...sources[type][index], [key]: value };

      sources[type][index] = updatedSource;
      return { ...sources };
    });
    setSelectedSourceArray(
      selectedSourceArray.map((source) => {
        if (source.type === type && _.isEqual(source.spec, spec)) {
          const updatedSpec = { ...source.spec, [key]: value };

          return { ...source, spec: updatedSpec };
        }
        return source;
      })
    );
  };

  const handleOutputSpecificationsChange = <T extends OutputType>(
    type: T,
    key: string,
    value: any,
    spec: any
  ) => {
    setSelectedOutputs((outputs) => {
      const index = _.findIndex(outputs[type], ['index', spec.index]);
      if (index === -1) {
        return outputs;
      }

      const updatedOutput = { ...outputs[type][index], [key]: value };
      outputs[type][index] = updatedOutput;

      return { ...outputs };
    });
    setSelectedOutputArray(
      selectedOutputArray.map((output) => {
        if (output.type === type && _.isEqual(output.spec, spec)) {
          const updatedSpec = { ...output.spec, [key]: value };
          return { ...output, spec: updatedSpec };
        } else {
          return output;
        }
      })
    );
  };

  const { mutate: createEvent, isLoading: isCreateLoading } = useMutation(
    EventService.createEvent,
    {
      onSuccess: async () => {
        triggerToast({ type: 'success', message: 'Created event!' });
        queryClient.invalidateQueries(['events']);
        setInputEventName('');
        setInputEventDate(addHours(new Date(), 1));
        setSelectedEventType(null);
        close();
      },
      onError() {
        triggerToast({ type: 'error', message: 'Unable to create event!' });
      },
    }
  );

  const { mutate: updateEvent, isLoading: isUpdateLoading } = useMutation(
    EventService.updateEvent,
    {
      onSuccess() {
        triggerToast({ message: 'Updated event!', type: 'success' });
        queryClient.invalidateQueries(['events']);
        queryClient.invalidateQueries(['event', event.id]);
        setInputEventName(event.name);
        setInputEventDate(new Date(event.scheduledTime));
        setSelectedEventType(null);
        close();
      },
      onError() {
        triggerToast({ message: 'Failed to update event!', type: 'error' });
      },
    }
  );

  const {
    data: awsResources,
    isFetching: isFetchingAWSResources,
    refetch: refetchAWSResources,
  } = useQuery(
    ['awsResources'],
    () => SourceService.listAWSResources(AWSResourceType.MEDIALIVE_DEVICE),
    { enabled: false }
  );

  const {
    data: slates,
    isFetching: isFetchingSlates,
    refetch: refetchSlates,
  } = useQuery(['slates'], () => SourceService.listSlates(currentUserGroup?.id), {
    enabled: false,
  });

  const isDateValid = useMemo(() => {
    const isValid = isAfter(new Date(inputEventDate), new Date());
    return isValid;
  }, [inputEventDate]);

  const isFormValid = () => {
    switch (currentStep) {
      case 0:
        const isEventNameValid = inputEventName?.length > 0;
        const isGroupValid = isUserAdmin && !isEditMode ? Boolean(selectedGroup?.id) : true;
        const isEventTypeValid = isUserAdmin ? Boolean(selectedEventType?.value) : true;
        return isDateValid && isEventNameValid && isGroupValid && isEventTypeValid;

      case 1:
        const filteredSources = _.omit(selectedSources, [SourceType.ZIXI_MULTI]);
        const validSources = Boolean(Object.keys(filteredSources).length > 0);
        if (validSources) {
          const filteredData = _.omit(filteredSources, [SourceType.ZIXI, SourceType.RTMP]);
          if (filteredData[SourceType.SRT]) {
            for (const source of filteredData[SourceType.SRT]) {
              if (source?.password && source?.password.length < 10) {
                return false;
              }
            }
          }
          const modifiedResources = _.mapValues(filteredData, (value) => {
            return value.map((source) => {
              if (source.mode === 'Listener') {
                const { host, password, streamId, ...rest } = source;
                return rest;
              } else if (source.mode === 'Caller') {
                const { streamId, password, ...rest } = source;
                return rest;
              } else {
                return source;
              }
            });
          });
          return checkObjectNotEmpty(modifiedResources);
        } else {
          return false;
        }
      case 2:
        const filteredOutputs = _.omit(selectedOutputs, [OutputType.COMMONCHANNEL]);
        const validOutputs = Boolean(Object.keys(filteredOutputs).length > 0);
        if (validOutputs) {
          if (selectedOutputs[OutputType.RTMP]) {
            for (const output of selectedOutputs[OutputType.RTMP]) {
              if (!output?.url?.startsWith('rtmp://') && !output?.url?.startsWith('rtmps://')) {
                return false;
              } else {
                if (output?.url?.split('://')[1] === '') {
                  return false;
                }
              }
            }
          }

          const filteredSelectedOutput = _.pickBy(filteredOutputs, (val) => !_.isEmpty(val));
          const modifiedResources = _.mapValues(filteredSelectedOutput, (value) => {
            return value.map((output) => {
              if (output.mode === 'Listener') {
                const { streamId, cidr, password, host, ...rest } = output;
                return rest;
              } else if (output.mode === 'Caller') {
                const { cidr, streamId, password, ...rest } = output;
                return rest;
              } else {
                return output;
              }
            });
          });
          if (selectedOutputs[OutputType.SRT]) {
            for (const output of selectedOutputs[OutputType.SRT]) {
              const cidrRegex = /^(?:\d{1,3}\.){3}\d{1,3}\/(?:[0-9]|[1-2][0-9]|3[0-2])$/;
              if (output?.cidr && !cidrRegex.test(output?.cidr)) {
                return false;
              }
              if (output?.password && output?.password.length < 10) {
                return false;
              }
            }
          }
          return checkObjectNotEmpty(modifiedResources);
        } else {
          return false;
        }
      default: {
        return true;
      }
    }
  };

  const handleCreateEvent = () => {
    const eventId = generateUniqueId({
      length: 8,
    }).toUpperCase();
    const group = isUserAdmin ? selectedGroup.id : currentUserGroup.id;
    createEvent({
      id: eventId,
      name: inputEventName,
      scheduledTime: inputEventDate.toISOString(),
      group,
      tag: selectedEventType.value,
      type: 'EVENT',
      sources: _.compact(
        Object.keys(selectedSources)
          .map((sourceType) => {
            const sourceSpecifications = selectedSources[sourceType];
            return sourceSpecifications.map((sourceSpecification) => {
              const { index, ...rest } = sourceSpecification;
              Object.entries(rest).forEach(([key, value]) => {
                if (['password', 'streamId'].includes(key) && !value) {
                  delete rest[key];
                }
              });
              if ('mode' in rest && rest.mode === 'Listener') {
                delete rest.host;
              }

              return (
                areAllObjectValuesValid(rest) && {
                  id: uuid(),
                  eventId,
                  type: sourceType,
                  group,
                  specifications: [
                    SourceType.SLATE_INPUT,
                    SourceType.ELEMENTAL_LINK,
                    SourceType.SRT,
                  ].includes(sourceType as SourceType)
                    ? (rest as Record<string, string>)
                    : {},
                  resources: [] as [],
                }
              );
            });
          })
          .flat()
      ),
      outputs: _.compact(
        Object.keys(selectedOutputs).map((outputType) => {
          const outputSpecifications = selectedOutputs[outputType];
          return outputSpecifications.map((outputSpecification) => {
            if (
              Object.keys(outputSpecification).includes('url') &&
              !outputSpecification.url?.startsWith('rtmps://') &&
              !outputSpecification.url.startsWith('rtmp://')
            ) {
              outputSpecification.url = 'rtmp://' + outputSpecification.url;
            }
            const { index, ...rest } = outputSpecification;
            Object.entries(rest).forEach(([key, value]) => {
              if (['password', 'streamId', 'cidr'].includes(key) && !value) {
                delete rest[key];
              }
            });
            if ('mode' in rest && rest.mode === 'Listener') {
              delete rest.streamId;
              delete rest.host;
            }
            if ('mode' in rest && rest.mode === 'Caller') {
              delete rest.cidr;
            }
            return (
              areAllObjectValuesValid(rest) && {
                id: uuid(),
                eventId,
                type: outputType,
                resources: [],
                specifications: rest,
                group,
                ...(rest.tag && { tag: rest.tag }),
              }
            );
          });
        })
      ).flat(),
    });
  };

  const handleUpdateEvent = () => {
    const updatedEvent: UpdateEventPayload = {
      ...event,
      name: inputEventName,
      scheduledTime: inputEventDate.toISOString(),
      tag: selectedEventType?.value,
      sources: _.compact(
        Object.keys(selectedSources).map((sourceType) => {
          const sourceSpecifications = selectedSources[sourceType];
          return sourceSpecifications.map((sourceSpecification) => {
            const existingSource = _.find(event.sources, ['id', sourceSpecification.index]);
            const { index, ...rest } = sourceSpecification;
            Object.entries(rest).forEach(([key, value]) => {
              if (['password', 'streamId'].includes(key) && !value) {
                delete rest[key];
              }
            });
            if ('mode' in rest && rest.mode === 'Listener') {
              delete rest.host;
            }
            return (
              areAllObjectValuesValid(rest) && {
                id: existingSource ? existingSource.id : uuid(),
                eventId: eventToEdit,
                type: sourceType,
                group: event.group,
                specifications: [
                  SourceType.SLATE_INPUT,
                  SourceType.ELEMENTAL_LINK,
                  SourceType.SRT,
                ].includes(sourceType as SourceType)
                  ? (rest as Record<string, string>)
                  : {},
                resources: existingSource ? existingSource['resources'] : [],
              }
            );
          });
        })
      ).flat(),
      outputs: _.compact(
        Object.keys(selectedOutputs).map((outputType) => {
          if (
            outputType === OutputType.COMMONCHANNEL &&
            !Object.keys(selectedOutputs).some(
              (outputType) => outputType === OutputType.RTMP || outputType === OutputType.HLS
            )
          ) {
            return;
          } //Note: If there is no HLS or RTMP output, then remove the Common Channel output

          const existingChannelOutput = _.find(event.outputs, ['type', OutputType.COMMONCHANNEL]);
          const outputSpecifications = selectedOutputs[outputType];
          return outputSpecifications.map((outputSpecification) => {
            const existingOutput = _.find(event.outputs, ['id', outputSpecification.index]);
            if (
              Object.keys(outputSpecification).includes('url') &&
              !outputSpecification.url?.startsWith('rtmps://') &&
              !outputSpecification.url.startsWith('rtmp://')
            ) {
              outputSpecification.url = 'rtmp://' + outputSpecification.url;
            }
            const { index, ...rest } = outputSpecification;
            Object.entries(rest).forEach(([key, value]) => {
              if (['password', 'streamId', 'cidr'].includes(key) && !value) {
                delete rest[key];
              }
            });
            if ('mode' in rest && rest.mode === 'Listener') {
              delete rest.streamId;
              delete rest.host;
            }
            if ('mode' in rest && rest.mode === 'Caller') {
              delete rest.cidr;
            }
            return (
              //Note: If there is no HLS or RTMP output, then remove the Common Channel output
              areAllObjectValuesValid(rest) && {
                id: existingOutput ? existingOutput.id : uuid(),
                eventId: eventToEdit,
                type: outputType,
                group: event.group,
                specifications: rest,
                resources: existingOutput ? existingOutput['resources'] : [],
                tag: outputSpecification.tag,
                /*
                 * The following code maintains the status of the Common Channel output.
                 * If event.outputs does not contain the channel output (i.e., no HLS or RTMP output) from the API,
                 * it implies that there's no common channel. Therefore, there's no need to create a status.
                 * Add status only if event.outputs includes the channel output.
                 */
                ...(((outputType === OutputType.HLS || outputType === OutputType.RTMP) &&
                  Object.keys(selectedOutputs).includes(OutputType.COMMONCHANNEL)) ||
                outputType === OutputType.COMMONCHANNEL
                  ? {
                      status: existingChannelOutput?.status,
                    }
                  : {}),
              }
            );
          });
        })
      ).flat(),
    };
    delete updatedEvent['stream'];
    delete updatedEvent['createdBy'];
    delete updatedEvent['broadcasterId'];
    updateEvent(updatedEvent);
  };
  const buttons: PopUpButtonProps[] = [
    currentStep !== 0
      ? {
          name: 'BACK',
          handler: async () => {
            if (currentStep === 2) {
              await refetchAWSResources();
              await refetchSlates();
            }
            setCurrentStep((step) => step - 1);
          },
          variant: 'outlined',
          isLoading: isFetchingAWSResources || isFetchingSlates,
        }
      : { name: 'CANCEL', handler: close, variant: 'outlined' },
    currentStep === EventSteps.length - 1
      ? {
          name: isEditMode ? 'UPDATE' : 'CREATE',
          handler: isEditMode ? handleUpdateEvent : handleCreateEvent,
          variant: 'contained',
          isLoading: isCreateLoading || isUpdateLoading,
        }
      : {
          name: 'NEXT',
          handler: async () => {
            if (currentStep === 0) {
              isMounted.current = true;
              await Promise.all([refetchAWSResources(), refetchSlates()]);
            }
            if (isMounted.current && isPopUpOpen) {
              setCurrentStep((step) => step + 1);
            }
          },
          variant: 'contained',
          isLoading:
            isMounted.current && isPopUpOpen ? isFetchingAWSResources || isFetchingSlates : false,
        },
  ];

  const handleEventNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputEventName(event.target.value);
  };

  const handleEventDateChange = (event: Date | null) => {
    setInputEventDate(event);
  };

  const renderSourcesMenu = () => {
    const unSelectedSources = getUnSelectedSources();
    return (
      <Menu
        anchorEl={addSourcesMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 190,
          },
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isAddSourcesMenuOpen}
        onClose={() => setAddSourcesMenuAnchorEl(null)}
        onClick={() => setAddSourcesMenuAnchorEl(null)}
      >
        {unSelectedSources.sort().map((sourceType) => {
          return (
            <MenuItem key={sourceType} onClick={() => handleSelectSource(sourceType)}>
              {SourceTypeDisplayName[sourceType]}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderOutputsMenu = () => {
    const unselectedOutputs = getUnSelectedOutputs();
    return (
      <Menu
        anchorEl={addOutputsMenuAnchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        PaperProps={{
          style: {
            width: 190,
          },
        }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isAddOutputsMenuOpen}
        onClose={() => setAddOutputsMenuAnchorEl(null)}
        onClick={() => setAddOutputsMenuAnchorEl(null)}
      >
        {unselectedOutputs.sort().map((outputType) => {
          return (
            <MenuItem key={outputType} onClick={() => handleSelectOutput(outputType)}>
              {OutputTypeDisplayName[outputType]}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  const renderSourceInputField = (
    sourceType: string,
    selectedSourceSpecification: any,
    spec?: any
  ) => {
    return (
      <EventElementInputBox
        key={sourceType}
        name={SourceTypeDisplayName[sourceType]}
        deleteTooltip="delete source"
        showDelete
        onDelete={() => handleOnUnSelectSource(sourceType as SourceType, spec)}
      >
        <EventInput
          type={sourceType as SourceType}
          spec={spec}
          details={selectedSourceSpecification}
          inputType={'SOURCE'}
          onChange={handleSourceSpecificationsChange}
          options={{
            awsResources: awsResources.filter((resource) =>
              Boolean(resource.details?.Name) && !isUserAdmin
                ? resource.group === currentUserGroup?.id
                : true
            ),
            slates: slates.filter((resource) =>
              !isUserAdmin ? resource.group === currentUserGroup?.id : true
            ),
          }}
          selectedSources={selectedSources}
          onClickRefreshSlates={refetchSlates}
          isFetchingSlates={isFetchingSlates}
          group={selectedGroup?.id}
        />
      </EventElementInputBox>
    );
  };

  const renderOutputInputField = (
    outputType: string,
    selectedOutputSpecification: any,
    spec?: any
  ) => {
    return (
      <Box
        style={{
          position: 'relative',
        }}
      >
        <EventElementInputBox
          key={outputType}
          showDelete
          name={OutputTypeDisplayName[outputType]}
          deleteTooltip="delete output"
          onDelete={() => handleOnUnSelectOutput(outputType as OutputType, spec)}
        >
          <EventInput
            spec={spec}
            inputType={'OUTPUT'}
            type={outputType as OutputType}
            details={selectedOutputSpecification}
            onChange={handleOutputSpecificationsChange}
          />
        </EventElementInputBox>
        <Box
          display="flex"
          justifyContent="flex-end"
          sx={{
            position: 'absolute',
            bottom: '0px',
            right: '50%',
          }}
        ></Box>
      </Box>
    );
  };

  useEffect(() => {
    if (isPopUpOpen) {
      setCurrentStep(0);
      setSelectedSources({});
      setSelectedOutputs({});
      setSelectedSourceArray([]);
      setSelectedOutputArray([]);
      setInputEventName('');
      setSelectedEventType(null);
      isMounted.current = false;
      setInputEventDate(addHours(new Date(), 1));
      !isUserAdmin ? setselectedGroup(currentUserGroup) : setselectedGroup(null);
    }
  }, [isPopUpOpen]);

  const {
    refetch: refetchEvent,
    data: event,
    isFetching: isEventRefetching,
  } = useQuery(['eventEdit', eventToEdit], () => EventService.getEvent(eventToEdit), {
    enabled: false,
    onSuccess(event) {
      setInputEventName(event.name);
      setInputEventDate(new Date(event.scheduledTime));
      setSelectedEventType(
        event.tag
          ? eventsTypeOptions.find((option) => option.label === event.tag)
            ? eventsTypeOptions.find((option) => option.label === event.tag)
            : { label: event.tag, value: event.tag }
          : null
      );

      const sourceMap = {};
      const sourceIndexArray = [];

      event.sources.map((source) => {
        if (!sourceMap[source.type]) {
          sourceMap[source.type] = [];
        }
        if (source.type === SourceType.SRT) {
          source.specifications = {
            host: '',
            ...source.specifications,
          };
        }
        sourceMap[source.type].push({ ...source.specifications, index: source.id });
        sourceIndexArray.push({
          type: source.type,
          spec: { ...source.specifications, index: source.id },
        });
      });
      setSelectedSourceArray(sourceIndexArray);
      const finalSourceMap = { ...selectedSources, ...sourceMap };
      setSelectedSources(finalSourceMap);

      const outputMap = {};
      const outputIndexMap = [];
      event.outputs.map((output) => {
        if (!outputMap[output.type]) {
          outputMap[output.type] = [];
        }
        if (output.type === OutputType.SRT) {
          output.specifications = {
            host: '',
            ...output.specifications,
          };
        }
        outputMap[output.type].push({
          ...output.specifications,
          ...(output.type !== OutputType.COMMONCHANNEL && { tag: output.tag }),
          index: output.id,
        });
        outputIndexMap.push({
          type: output.type,
          spec: {
            ...output.specifications,
            ...(output.type !== OutputType.COMMONCHANNEL && { tag: output.tag }),
            index: output.id,
          },
        });
      });

      setSelectedOutputArray(outputIndexMap);
      const finalOutputMap = { ...selectedOutputs, ...outputMap };
      setSelectedOutputs(finalOutputMap);
    },
  });
  useEffect(() => {
    if (isPopUpOpen && isEditMode) {
      refetchEvent();
      setCurrentStep(0);
      setSelectedEventType(null);
      isMounted.current = false;
    }
  }, [isPopUpOpen, isEditMode]);

  const calculateValuesLength = (obj: any, excludeKeys: any[]) => {
    const objWithoutExcludedKeys = _.omit(obj, excludeKeys);
    const objValues = _.values(objWithoutExcludedKeys);
    return _.flatten(objValues).length;
  };

  const selectedOutputsValuesLength = calculateValuesLength(selectedOutputs, [
    OutputType.COMMONCHANNEL,
  ]);
  const selectedSourcesValuesLength = calculateValuesLength(selectedSources, [
    SourceType.ZIXI_MULTI,
  ]);

  return (
    <PopUp
      disableCTA={isCreateLoading || isUpdateLoading || !isFormValid()}
      isPopUpOpen={isPopUpOpen}
      title={EventSteps[currentStep]?.name || ''}
      buttons={buttons}
      description={
        <Typography variant="body2" sx={{ color: 'grey.600' }}>
          step {currentStep + 1}/{EventSteps.length}
        </Typography>
      }
      close={close}
      maxWidth="sm"
      fullWidth
    >
      {isEventRefetching ? (
        <Center minHeight="300px">
          <CircularProgress />
        </Center>
      ) : (
        <Box display="flex" flexDirection="column">
          {currentStep === 0 ? (
            <>
              <TextField
                disabled={isCreateLoading || isUpdateLoading}
                size="small"
                label="Event name"
                value={inputEventName}
                onChange={handleEventNameChange}
              />
              <Typography variant="subtitle2" mt="20px" sx={{ fontWeight: 600, color: 'grey.600' }}>
                Schedule streaming
              </Typography>
              <Box display="flex" flexDirection="column" gap="20px" pt="15px">
                <ThemeProvider theme={calenderTheme}>
                  <Box display={{ md: 'block', xs: 'none' }}>
                    <Tooltip placement="bottom" title={DATE_FORMAT}>
                      <DesktopDatePicker
                        disabled={isCreateLoading || isUpdateLoading}
                        label="Schedule date"
                        format={DATE_FORMAT}
                        value={inputEventDate}
                        onChange={handleEventDateChange}
                        slotProps={{
                          textField: {
                            size: 'small',
                            fullWidth: true,
                          },
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Box display={{ xs: 'block', md: 'none' }}>
                    <MobileDatePicker
                      disabled={isCreateLoading || isUpdateLoading}
                      label="Schedule date"
                      format={DATE_FORMAT}
                      value={inputEventDate}
                      onChange={handleEventDateChange}
                      slotProps={{
                        textField: {
                          size: 'small',
                          fullWidth: true,
                        },
                      }}
                    />
                  </Box>
                  <DesktopTimePicker
                    disabled={isCreateLoading || isUpdateLoading}
                    label="Time"
                    value={inputEventDate}
                    onChange={handleEventDateChange}
                    format={`hh:mm aa '${timeZone}'`}
                    timeSteps={{ hours: 1, minutes: 1, seconds: 5 }}
                    slotProps={{
                      textField: {
                        size: 'small',
                        fullWidth: true,
                      },
                    }}
                  />
                  {!isEditMode && (
                    <Autocomplete
                      value={selectedGroup}
                      size="small"
                      disabled={!isUserAdmin}
                      options={nonAdminGroups}
                      onChange={(_, value: GroupInfo) => setselectedGroup(value)}
                      getOptionLabel={(option: GroupInfo) => `${option.name} (${option.id})`}
                      renderInput={(params) => <TextField {...params} label="Select Group" />}
                    />
                  )}
                  <AutoCompleteInput
                    label={'Event Type'}
                    freeSolo={false}
                    options={eventsTypeOptions}
                    onChange={setSelectedEventType}
                    value={selectedEventType}
                  />
                </ThemeProvider>
              </Box>
              {!isDateValid && (
                <Typography variant="caption" sx={{ color: 'error.main', marginTop: '10px' }}>
                  *Schedule time cannot be less than current time
                </Typography>
              )}
            </>
          ) : currentStep === 1 ? (
            <>
              {selectedSourceArray
                .filter((selectedSource) => selectedSource.type !== SourceType.ZIXI_MULTI)
                .map((source) => {
                  const selectedSource = selectedSources[source.type];
                  const sourceSpecification = _.find(selectedSource, source.spec);
                  return renderSourceInputField(source.type, sourceSpecification, source.spec);
                })}
              <Box display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  //remove this to enable adding multiple source
                  disabled={
                    getUnSelectedSources().length === 0 ||
                    selectedSourcesValuesLength === SourceCount
                  }
                  startIcon={<AddRounded />}
                  onClick={(e) => setAddSourcesMenuAnchorEl(e.currentTarget)}
                >
                  {selectedSourcesValuesLength > 0 ? 'Add Another Source' : 'Add Source'}
                </Button>
                {renderSourcesMenu()}
              </Box>
            </>
          ) : currentStep === 2 ? (
            <>
              {selectedOutputArray
                .filter((selectedOutput) => selectedOutput.type !== OutputType.COMMONCHANNEL)
                .map((output) => {
                  const selectedOutput = selectedOutputs[output.type];
                  const outputSpecification = _.find(selectedOutput, output.spec);
                  return renderOutputInputField(output?.type, outputSpecification, output.spec);
                })}
              <Box display="flex" justifyContent="flex-end">
                <Button
                  size="small"
                  variant="outlined"
                  disabled={
                    getUnSelectedOutputs().length === 0 ||
                    selectedOutputsValuesLength === OutputsCount
                  }
                  startIcon={<AddRounded />}
                  onClick={(e) => setAddOutputsMenuAnchorEl(e.currentTarget)}
                >
                  {selectedOutputsValuesLength > 0 ? 'Add Another Output' : 'Add Output'}
                </Button>
                {renderOutputsMenu()}
              </Box>
            </>
          ) : (
            <></>
          )}
        </Box>
      )}
    </PopUp>
  );
}
